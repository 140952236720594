import React, { useState } from "react"
import styled from 'styled-components'
import { Link } from "gatsby"

import { Button, Group, TextContent } from "../styles/"
import { FontSize } from "../variables/"

export const Field = styled.div`
	text-align: left;
	margin-bottom: 20px;
	${FontSize.xsm}
	p {
		margin-bottom: 5px;
	}
	input, textarea {
		width: 100%;
		display: block;
		outline: none;
		border: none;
		padding: 10px;
		
		&::-webkit-input-placeholder, 
		&::-moz-placeholder, 
		&:-ms-input-placeholder,
		&:-moz-placeholder { 
			color: #222;
			opacity: 1;
		}
		&:-webkit-autofill {
		    -webkit-box-shadow: 0 0 0px 1000px white inset;
		    -webkit-text-fill-color: #222!important;
		}
		&[disabled] {
			pointer-events:none;
		}
	}
	input {
		border-bottom: 1px solid #222;
	}
	textarea {
		border: 1px solid #222;
		max-width: 100%;
		min-width: 100%;
		max-height: 200px;
		min-height: 150px;
	}
`
export const CheckField = styled.div`
	display: flex;
	align-items: baseline;
	margin-bottom: 20px;
	${FontSize.xsm}
	input {
		margin-right: 5px;
	}
`
export const Form = () => {

	const [isConsent, setConsent] = useState(false);

	return (
		<form name="enquiry" method="post" netlify-honeypot="bot-field" data-netlify="true" action="/thanks">
			<input type="hidden" name="form-name" value="enquiry" />
			<input type="hidden" name="bot-field" />
			<Field>
				<label>
					<p>Full name*</p>
					<input type="text" name="name" required/>
				</label>
			</Field>

			<Field>
				<label>
					<p>Email address*</p>
					<input type="email" name="email" required/>
				</label>
			</Field>
			<Field>
				<label>
					<p>Message*</p>
					<textarea name="message" required/>
				</label>
			</Field>
			<CheckField>
				<input 
					id="consent"
					type='checkbox'
					name="consent"  
					onChange={() => setConsent(!isConsent)} 
					required/>
				<label htmlFor="consent">
					Please confirm you’re happy for the information submitted in this form, to be stored and used to contact you for purposes of answering your enquiry.
				</label>
			</CheckField>
			<Group>
				<TextContent>
					<p>Take a look at our <Link className="link" to="/privacy-policy">privacy policy</Link> for more information on how we store and use your data</p>
				</TextContent>
			</Group>
			{ isConsent &&
				<Button type="submit">Submit</Button>
			}
		</form>
	)
}
