import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export const Image = ({ imageData, imageAlt }) => {
	const imageObject = getImage(imageData)
	return (
		imageObject &&
			<GatsbyImage image={imageObject} alt={imageAlt} />
	)
}
