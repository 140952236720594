import styled from 'styled-components'

import { BreakPoint } from '../variables';

const mobWidth = props => props.mob ? props.mob : 1
const smlWidth = props => props.sml ? props.sml : mobWidth
const medWidth = props => props.med ? props.med : smlWidth
const lrgWidth = props => props.lrg ? props.lrg : medWidth

export const Grid = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: repeat(${mobWidth}, 1fr);
	grid-template-rows: 1fr;
	grid-column-gap: ${props => props.colGap ? props.colGap+"px" : "30px"};
	grid-row-gap: 30px;
	justify-content: space-evenly;
	@media ${BreakPoint.sm} { 
		grid-template-columns: repeat(${smlWidth}, 1fr);
	}
	@media ${BreakPoint.md} { 
		grid-template-columns: repeat(${medWidth}, 1fr);
	}
	@media ${BreakPoint.lg} { 
		grid-template-columns: repeat(${lrgWidth}, 1fr);
	}
`

export const GridItem = styled.div`
	width: 100%;
	background: ${props => props.background ? props.background : "none"};
	grid-column-start: ${props => props.mob.start};
	grid-column-end: ${props => props.mob.end};
	align-self: ${props => props.align ? props.align : "stretch"};
	@media ${BreakPoint.sm} {
		grid-column-start: ${props => props.sml.start};
		grid-column-end: ${props => props.sml.end};
	}
	@media ${BreakPoint.md} { 
		grid-column-start: ${props => props.med.start};
		grid-column-end: ${props => props.med.end};
	}
	@media ${BreakPoint.lg} { 
		grid-column-start: ${props => props.lrg.start};
		grid-column-end: ${props => props.lrg.end};
	}
`