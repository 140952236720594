import React from "react"
import styled, { css }  from 'styled-components'
import { Autoplay, Pagination, A11y } from 'swiper';
import { Swiper } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

const Wrapper = styled(Swiper)`
	${props => props.paginationBelow && css`
		.swiper-pagination {
			position: static!important;
			margin-top: 30px!important;
		}
	`}
	.swiper-pagination-bullet-active {
		background-color: #000 !important;
	}
`

export const SwiperWrapper = ({ children, paginationBelow }) => {
	return (
		<Wrapper
			paginationBelow={paginationBelow}
			modules={[Autoplay, Pagination, A11y]}
			spaceBetween={0}
			slidesPerView={1}
			autoplay
			loop
			pagination={{ clickable: true }}>
			{children}
		</Wrapper>
	)
}
