import styled from 'styled-components'
import { FontSize } from '../variables/'

export const SuccessText = styled.div`
	${FontSize.xxsm}
	color: green;
	margin: 20px 0;
	&:last-child {
		margin-bottom: 0;
	}
`
