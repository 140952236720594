import React from "react"
import styled from 'styled-components'

//components
import { Logo } from "../partials/"
import { Restrict } from "../styles/"

const Wrapper = styled.header`
	padding: 20px;
`
const Content = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	> * {
		flex: 1;
	}
`
const Center = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`

export const Header = () => {

	return (
		<Wrapper>
			<Restrict>
				<Content>
					<div></div>
					<Center>
						<Logo/>
					</Center>
					<div></div>
				</Content>
			</Restrict>
		</Wrapper>
	)
}
