import styled from 'styled-components'

import { BreakPoint } from '../variables/';

export const Section = styled.section`
	text-align: ${props => props.center ? "center" : "left"};
	margin: 40px 20px;	
	@media ${BreakPoint.md} { 
		margin: 60px;
	}
`