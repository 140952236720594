import { Link } from "gatsby"
import styled, { css } from 'styled-components'
import { FontSize, Green, Black } from '../variables/'

export const ButtonStyles = css`
	cursor: pointer;
	${props => props.simple ? css`
		position: relative;
		background: transparent;
		text-decoration: underline;
		text-underline-offset: 2px;
		&:hover {
			color: ${Green.primary};
		}
	`: css`
		text-decoration: none;
		width: ${props => props.full ? '100%' : 'auto'};
		display: inline-block;
		cursor: pointer;
		text-align: center;
		padding: 15px 20px;
		border: 2px solid transparent;
		border-radius: 40px;
		background: ${props => props.bgcolour ? props.bgcolour.primary : Black.primary};
		color: ${props => props.bgcolour ? props.bgcolour.secondary : Black.secondary};
		text-transform: uppercase;
		font-family: 'Poppins', sans-serif;
		${FontSize.xsm}
		&:hover {
			background: transparent;
			border-color: ${props => props.bgcolour ? props.bgcolour.primary : Black.primary};
			color: ${props => props.bgcolour ? props.bgcolour.primary : Black.primary};
		}
	`
	}
	${props => props.submit && css`
		border-color: ${Green.primary};
		background: ${Green.primary};
		color: ${Green.secondary};
		&:hover {
			border-color: ${Green.secondary};
			color: ${Green.secondary};
		}
	`}
`
export const ButtonLink = styled(Link)`
	${ButtonStyles}
`
export const ButtonExternal = styled.a`
	${ButtonStyles}
`
export const Button = styled.button`
	${ButtonStyles}
`
