import React from "react"
import { useSiteMetadata } from "../hooks/use-site-metadata"

import Favicon from "../../images/logos/favicon.jpg"

export const Meta = ({ title, description, page, pageType, pathname, robots, children }) => {
  const {
    title: defaultTitle,
    description: defaultDescription,
    brand,
    slogan,
    email,
    telephone,
    author,
    colour,
    logo,
    defaultImage,
    siteUrl,
  } = useSiteMetadata()

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: defaultImage,
    colour: colour,
    url: `${siteUrl}${pathname || ``}`,
    robots: robots || 'all',
    lang: `en-gb`
  }

  const schemaOrgOrganisation = {
    '@context': 'https://schema.org',
    '@type': 'HealthAndBeautyBusiness',
    name: brand,
    slogan: slogan,
    description: description,
    url: siteUrl,
    openingHours: ["Tu-We 09:00-17:30", "Th 09:00-19:00", "Fr 09:00-17:30", "Sa 09:00-17:00"],
    address: {
      "@type": "PostalAddress",
      addressLocality: "Jesmond",
      addressRegion: "Newcastle upon Tyne",
      postalCode: "NE2 1LB",
      streetAddress: "261 Jesmond Road",
      addressCountry: "United Kingdom",
    },
    areaServed: ["United Kingdom", "Northumberland", "Newcastle upon Tyne", "Newcastle", "Jesmond"],
    contactPoint: [
      {
        "@type": "ContactPoint",
        telephone: telephone,
        contactType: "Service enquiries",
        email: email,
        url: `${siteUrl}/contact/`,
      }],
    geo: [
      {
        "@type": "GeoCoordinates",
        latitude: "54.987768500",
        longitude: "-1.593966780",
      }],
    knowsAbout: ["Hairdressing", "Women's hairdressing", "Men's hairdressing", "Childen's hairdressing", "Balayage", "Root stretch", "Colour treatment", "Skin fade", "Cut & Blowdry", "Smartbond", "Bridal hair"],
    logo: logo,
    image: defaultImage,
    telephone: telephone,
    email: email,
    priceRange: "Full list of prices on our services page",
  }

  const schemaOrgWebPage = {
    '@context': 'https://schema.org',
    '@type': pageType || 'WebPage',
    inLanguage: seo.lang,
    url: seo.url,
    mainEntityOfPage: seo.url,
    name: `${page} page`,
    headline: title,
    description: seo.description,

    author: {
      '@type': 'Person',
      name: author,
    },
    copyrightHolder: {
      '@type': 'Person',
      name: author,
    },
    copyrightYear: '2020',
    creator: {
      '@type': 'Person',
      name: author,
    },
    publisher: {
      '@type': 'Person',
      name: author,
    },
    datePublished: '2020-02-27T11:00:00+00:00',
    image: {
      '@type': 'ImageObject',
      url: defaultImage,
    },
  }

  return (
    <>
      <html lang={seo.lang}/>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />

      <meta name="og:locale" content={seo.lang} />
      <meta name="og:site_name" content={seo.brand} />
      <meta name="og:type" content="website" />
      <meta name="og:title" content={seo.title} />
      <meta name="og:description" content={seo.description} />
      <meta name="og:image" content={seo.image} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:url" content={seo.url} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />

      <meta name="theme-color" content={seo.colour} />
      <meta name="robots" content={seo.robots}/>
      <link rel="icon" href={Favicon} />
      
      {children}
      <script type="application/ld+json">{JSON.stringify(schemaOrgOrganisation)}</script>
      <script type="application/ld+json">{JSON.stringify(schemaOrgWebPage)}</script>
    </>
  )
}