import React from "react"
import styled from 'styled-components'

import { Section, TextContent } from "../styles/"
import { FacebookIcon, InstagramIcon } from "../variables/"

const Icons = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`
export const Social = () => {

	return (
		<Section center>
			<TextContent center>
				<h2><strong>Follow</strong> us</h2>
				<Icons>
					<i><a href="https://www.facebook.com/PanelleHairStudio/" target="_blank" rel="noopener noreferrer"><FacebookIcon/></a></i>
					<i><a href="https://www.instagram.com/panellehairstudio/" target="_blank" rel="noopener noreferrer"><InstagramIcon/></a></i>
				</Icons>
			</TextContent>
		</Section>
	)
}
