import { darken, lighten } from 'polished'

//Black
export const Black = {
	primary: "#1d1d1d",
	secondary: "#fff" 
}
//White
export const White = {
	primary: "#fff",
	secondary: "#1d1d1d" 
}
//Grey
export const Grey = {
	primary: "#eee",
	secondary: "#1d1d1d"
}

const pink = "#e9bdc5"
const blue = "#b3c8db"

export const Pink = pink;
export const LightPink = lighten(0.1, pink);
export const LighterPink = lighten(0.14, pink);
export const LightestPink = lighten(0.16, pink);

export const Blue = blue;
export const LightBlue = lighten(0.1, blue);
export const LighterBlue = lighten(0.14, blue);
export const LightestBlue = lighten(0.16, blue);