import React from "react"

export const ContactInfo = () => {

	return (
		<>
			<p>
				261 Jesmond Road<br/>
				Newcastle upon Tyne<br/>
				NE2 1LB
			</p>
			<p>
				Tel: <a href="tel:01912816865">0191 281 6865</a><br/>
				Email: <a href="mailto:hello@panellehairstudio.co.uk">hello@panellehairstudio.co.uk</a>
			</p>
		</>
	)
}
