import React from "react"
import PropTypes from "prop-types"

//redux
import { Provider } from 'react-redux'
import store from '../store/configureStore'

export const  RootLayout = ({ children }) => {

  return (
      <Provider store={store}>
        { children }
      </Provider>
	)
}

RootLayout.propTypes = {
  children: PropTypes.node.isRequired,
}
