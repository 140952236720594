import React from "react"
import { Link } from "gatsby"
import styled from 'styled-components'

//components
import { Logo } from "../partials/"
import { ContactInfo, OpeningHours } from "../special/"
import { Restrict, Grid, GridItem, TextContent } from "../styles/"
import { FontSize } from "../variables/"

const Wrapper = styled.header`
	padding: 40px;
	background: #191A1F;
	color: white;
	${FontSize.xsm}
	border-bottom: 20px solid #232228;
	a {
		color: inherit;
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
`
const FooterLogo = styled.div`
	margin-bottom: 20px;
`
const FooterNav = styled.nav`
	li {
		margin-bottom: 5px;
		&:last-child {
			margin: 0;
		}
	}
`

export const Footer = () => {

	return (
		<Wrapper>
			<Restrict restrict="lrg">
				<Grid mob={1} sml={1} med={2} lrg={5}>
					<GridItem
						mob={{ start:1, end:2 }} 
						sml={{ start:1, end:2 }} 
						med={{ start:1, end:2 }} 
						lrg={{ start:1, end:2 }}>
						<FooterLogo>
							<Logo invert/>
						</FooterLogo>
						<FooterNav>
							<ul>
								<li><Link to="/">Home</Link></li>
								<li><Link to="/about">About us</Link></li>
								<li><Link to="/services">Services</Link></li>
								<li><Link to="/gallery">Gallery</Link></li>
								<li><Link to="/contact">Contact us</Link></li>
								<li><Link to="/privacy-policy">Privacy policy</Link></li>
							</ul>
						</FooterNav>
					</GridItem>
					<GridItem
						mob={{ start:1, end:2 }} 
						sml={{ start:1, end:2 }} 
						med={{ start:2, end:3 }} 
						lrg={{ start:2, end:4 }}>
						<TextContent>
							<h4><strong>Contact</strong> information</h4>
							<ContactInfo/>
						</TextContent>
					</GridItem>
					<GridItem
						mob={{ start:1, end:2 }} 
						sml={{ start:1, end:2 }} 
						med={{ start:1, end:3 }} 
						lrg={{ start:4, end:6 }}>
						<TextContent>
							<h4><strong>Opening</strong> times</h4>
							<OpeningHours/>
						</TextContent>
					</GridItem>
				</Grid>
			</Restrict>
		</Wrapper>
	)
}
