import React from "react"
import { Link } from "gatsby"
import styled from 'styled-components'

import { Restrict } from "../styles/"
import { BreakPoint } from '../variables/';

const NavWrapper = styled.nav`
	margin-bottom: 15px;
`
const NavContent = styled.div`
	@media ${BreakPoint.md} { 
		display: flex;
	}
	ul {
		list-style: none;
		margin: 0;
		flex: 1;
		text-align: left;
		padding: 0 20px;
		@media ${BreakPoint.md} { 
			text-align: center;
			&:first-child {
				text-align: left;
			}
			&:last-child {
				text-align: right;
			}
		}
		li {
			display: inline-block;
			margin: 0 0 5px;
			font-size: 1.4rem;
			&:after {
				content: '/';
				margin: 0 5px;
			}
			&:last-child {
				&:after {
					display: none;
				}
			}
		}
		a {
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
	}
`
export const Nav = () => (
	<NavWrapper>
		<Restrict restrict="lrg">
			<NavContent>
				<ul>
					<li><Link to="/services/#for-him">For <strong>Him</strong></Link></li>
					<li><Link to="/services/#for-her">For <strong>Her</strong></Link></li>
				</ul>
				<ul>
					<li><Link to="/"><strong>Home</strong></Link></li>
					<li><Link to="/about"><strong>About us</strong></Link></li>
					<li><Link to="/services"><strong>Services</strong></Link></li>
				</ul>
				<ul>
					<li><a href="tel:01912816865">0191 281 6865</a></li>
					<li><Link to="/contact"><strong>Contact</strong></Link></li>
				</ul>
			</NavContent>
		</Restrict>
	</NavWrapper>
)

export default Nav