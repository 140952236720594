import styled from 'styled-components'
import { Grey, GreyLight } from '../variables/'

export const Component = styled.div`
	background: ${props => props.theme === "dark" ? GreyLight.primary : Grey.primary};
	color: ${props => props.theme === "dark" ? GreyLight.secondary : Grey.secondary};
	border-radius: 5px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`