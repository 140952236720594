import { graphql, useStaticQuery } from "gatsby"

export const useSiteMetadata = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
            brand
            slogan
            description
            email
            telephone
            author
            colour
            logo
            defaultImage
            siteUrl
        }
      }
    }
  `)

  return data.site.siteMetadata
}