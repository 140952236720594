import styled from 'styled-components'

import { FontSize, LightestPink, LighterPink, LightPink} from "../variables"

export const Table = styled.table`
	width: 100%;
    table-layout: fixed;
    border-spacing: 0;
	tr {
		background: ${LightestPink};
		&:nth-child(odd) {
			background: ${LighterPink};
		}
		td {
			&:last-child {
				text-align: right;
			}
		}
	}
	th, td {
		padding: 15px;
		${FontSize.xxsm}
	}
	th {
		background: ${LightPink};
	}
	td {
		
	}
`
