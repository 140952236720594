import React from "react"
import styled from 'styled-components'

const Table = styled.table`
	th {
		padding-right: 30px;
	}
`

export const OpeningHours = () => {

	return (
		<Table>
			<tbody>
				<tr>
					<th>Monday</th>
					<td>Closed</td>
				</tr>
				<tr>
					<th>Tuesday</th>
					<td>Closed</td>
				</tr>
				<tr>
					<th>Wednesday</th>
					<td>09:00 - 17:30</td>
				</tr>
				<tr>
					<th>Thursday</th>
					<td>09:00 - 19:00</td>
				</tr>
				<tr>
					<th>Friday</th>
					<td>09:00 - 17:30</td>
				</tr>
				<tr>
					<th>Saturday</th>
					<td>09:00 - 17:00</td>
				</tr>
				<tr>
					<th>Sunday</th>
					<td>Closed</td>
				</tr>
			</tbody>
		</Table>
	)
}
